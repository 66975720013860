<template>
  <div class="content">
    <div class="article-type-container tochscroll">
      <div class="article-type">
        <md-tab-bar
          v-model="current"
          :items="labellist"
          @change="changeTab"
          :maxLength="5"
          :inkcolor="colorprimary"
        />
      </div>
    </div>
    <div v-show="!isNull">
      <md-scroll-view
        class="scroll"
        ref="scrollView"
        auto-reflow
        :scrolling-x="false"
        @end-reached="loadMorechange"
      >
        <div class="scroll">
          <div
            class="poster"
            v-for="(item, index) in imgLists"
            :key="index"
            @click="toCardDetail(item.sno)"
          >
            <div class="cardItem">
              <img class="img" :src="ossurl + item.pichttp" />
              <div class="text">
                <div class="left">
                  <span>{{ showname }}</span>
                </div>
                <div class="right">
                  <md-icon name="visible" color="white" size="lg"></md-icon>
                  <span> {{ item.sharenum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more" :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata" v-show="isNull">
      <img class="kongimg" src="../../../../assets/image/null.png" />
    </div>
    <div class="tips"
         :style="`backgroundColor:${colorprimary}`"
	 @click="toMyCard">我的贺卡</div>
  </div>
</template>
<script>
import { selectKJFL, selectPage } from '@/api/abt/customerOperation/common'
import initWebSocket from '@/mixins/websock'
import { getStorage } from '@/lib/util'
import { mapGetters } from 'vuex'
import loadMorechange from '@/mixins/loadMorechange'
export default {
  mixins: [initWebSocket, loadMorechange],
  data () {
    return {
      websock: null,
      ossurl: '',
      pageNo: 1,
      size: 6,
      total: 0,
      totalPage: 0,
      loading: false,
      height: '',
      tabclass: '',
      stagcode: '',
      labellist: [
        // {
        //   tagname: '全部',
        //   tagcode: ''
        // }
      ],
      imgLists: [],
      closeWebsock: false,
      current: '',
      showname: '',
      listpageNo: 1,
      listsize: 6,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      isNull: true,
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.colorprimary = getStorage('theme', '')
    this.height = document.body.clientHeight
    this.getSelectList()
  },
  computed: {
    ...mapGetters(['getossurl', 'getInfo', 'getUser'])
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 60}px`
  },
  methods: {
    getSelectList () {
      selectKJFL({ tagcode: '4' }).then(res => {
        this.labellist = this.labellist.concat(res.data.data)
        this.labellist.forEach((item, index) => {
          item.name = item.tagcode
          item.label = item.tagname
        })
        this.stagcode = this.labellist[0].name
        this.current = this.labellist[0].name
        this.showname = this.labellist[0].label
        this.getData()

        // this.labellist = res.data.data
        // for (var i = 0; i < this.labellist.length; i++) {
        //   this.labellist[i].value = this.labellist[i].tagcode
        //   this.labellist[i].label = this.labellist[i].tagname
        // }
      })
    },
    getData () {
      let data = {
        page: this.listpageNo,
        size: this.listsize,
        btagcode: '4',
        stagcode: this.stagcode,
        flag: '0'
      }
      selectPage(data).then(res => {
        this.imgLists =
          this.imgLists == []
            ? res.data.data.rows
            : this.imgLists.concat(res.data.data.rows)
        if (this.imgLists.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        // console.log('--------------请求数据中--------------')
        // console.log('请求数据中总页数' + this.listtotalPage)
        // console.log('请求数据中当前页' + this.listpageNo)
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
        // console.log('请求数据中加载状态' + this.loadflag)
        // console.log('请求数据中是否停止加载' + this.listFinished)
      })
    },
    changeTab (item, index, prevIndex) {
      this.showname = item.label
      this.stagcode = item.name
      this.listpageNo = 1
      this.imgLists = []
      this.listFinished = false
      this.loadflag = false
      this.$refs.scrollView.finishLoadMore()
      // if (!this.isNull) {
      //   this.$refs.scrollView.finishLoadMore()
      // }
      // this.isNull = false
      // console.log('--------------切换中--------------')
      // console.log('切换时总页数' + this.listtotalPage)
      // console.log('切换时当前页' + this.listpageNo)
      // console.log('切换时加载状态' + this.loadflag)
      // console.log('切换时是否停止加载' + this.listFinished)
      this.getData()
    },
    toMyCard () {
      this.$router.push('/mycard')
    },
    toCardDetail (val) {
      // this.$router.push('/greetingcard/carddetail')
      this.$router.push({ name: 'carddetail', query: { sno: val } })
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll {
  overflow-y: auto;
}

.tabcss {
  color: rgb(1, 84, 164) !important;
  border-bottom: 1px solid rgb(1, 84, 164);
}

.article-type-container {
  position: relative;
  height: 130px;

  .article-type {
    background: #fcfcfc;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    height: 130px;
    // margin-right:130px;
    border-bottom: 1px solid #f0f0f0;
  }
}

// .type-lists
// font-size:0.4rem;
// display: flex;
// width: auto;
// white-space: nowrap;
// li
// padding: 40px 40px 20px;
// font-size: 0.4rem;
// background-color: white;
// font-weight: 900;
// .fdj
// width: 130px;
// position: absolute;
// top: 0;
// right: 0;
// height:130px;
// background-image: url(../../../../assets/abt/img/searchwz.png);
// background-repeat: no-repeat;
// background-size: auto 40%;
// background-position: center;
// background-color: white;
// border-bottom: 1px solid #f0f0f0;
.poster {
  float: left;
  width: 50%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .cardItem {
    position: relative;

    .img {
      width: 100%;
      height: 800px;
      display: block;
    }

    .text {
      width: 100%;
      position: absolute;
      bottom: 5px;
      padding: 20px 20px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;

      .left {
        span {
          font-size: 35px;
        }
      }

      .right {
        span {
          font-size: 40px;
        }
      }
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

/deep/ .scroll-view-container {
  margin-top: 0.1rem;
}

/deep/.md-tab-bar-item {
  font-size: 40px;
  font-weight: 600;
  color: #000;
}
</style>
